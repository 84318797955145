import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{}}>{`TODO:
ACC, BAK, NIL, LEFT, RIGHT, UP, DOWN, ANY, LAST
`}</code></pre>
    <h3 {...{
      "id": "instructions"
    }}>{`Instructions`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`INST`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Syntax`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NOP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`NOP`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A no-op (technically ADD NIL)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MOV`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`MOV {SRC}, {DST}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Reads {SRC} and writes to {DST}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SWP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SWP`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Exchanges ACC and BAK values`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SAV`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SAV`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Writes ACC to BAK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ADD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ADD {SRC}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Adds {SRC} to ACC`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SUB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SUB {SRC}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Subtracts {SRC} from ACC`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NEG`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`NEG`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Flips ACC's sign`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`JMP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`JMP {LABEL}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jumps to {LABEL}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`JEZ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`JEZ {LABEL}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If ACC == 0, jumps to {LABEL}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`JNZ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`JNZ {LABEL}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If ACC != 0, jumps to {LABEL}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`JGZ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`JGZ {LABEL}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If ACC > 0, jumps to {LABEL}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`JLZ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`JLZ {LABEL}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If ACC < 0, jumps to {LABEL}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`JRO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`JRO {SRC}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jumps to instruction at offset {SRC}`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`Comments start `}<inlineCode parentName="p">{`#`}</inlineCode></p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Labels have the format `}<inlineCode parentName="p">{`{LABEL}:`}</inlineCode>{`, either at the start of a line or on its own line`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Breakpoints are used by adding `}<inlineCode parentName="p">{`!`}</inlineCode>{` to the beginning of a line`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{}}>{`TODO:
Keyboard shortcuts
Viz Module?!
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      